import { AiOutlineDribbble, AiFillGithub } from 'react-icons/ai';
import { FaCoffee } from 'react-icons/fa';


const data = [
    { id: 1, link: 'https://www.buymeacoffee.com/joelarthur', icon: <FaCoffee />},
    { id: 3, link: 'https://grayspace-sable.vercel.app/', icon: <AiOutlineDribbble /> },
    { id: 2, link: 'https://github.com/Arthurjoel12', icon: <AiFillGithub /> },
];

export default data;
